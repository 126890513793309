.data-question-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
}

.data-question-container-row {
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #bdbdbd;
    border-radius: 0.25rem;

}
.data-question-answer-title {
    margin-left: 5px;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.data-question-input {
    margin: 10px;
    font-size: 1.5rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    width: 45rem;
}

.data-question-answer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}

.data-question-answer-label {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.data-question-add-question-button {
    margin: 15px;
    background-color: #009be5;
    color: white;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    width: 20%;
    height: calc(2.25rem + 2px);
    border-radius: 0.35rem;
    text-align: center;
    border: 1px solid #bdbdbd;
}
.data-question-add-question-button:hover{
    background-color: white;
    color:#009be5;
    border: 1px solid #009be5;
}
.data-question-save-question{
    position: relative;
    right: 0;
    top:0;
    margin: 15px;
    background-color: #009be5;
    color: white;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    width: 10%;
    height: calc(2.25rem + 2px);
    border-radius: 0.35rem;
    text-align: center;
    border: 1px solid #bdbdbd;
}
.data-question-save-question:hover{
    background-color: white;
    color:#009be5;
    border: 1px solid #009be5;
}

.data-question-delete-button {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin: 10px;
}
.invalid{
    border: 1px solid red;
}
.data-question-delete-button:before, .data-question-delete-button:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: red;
}

.data-question-delete-button:before {
    transform: translate(-50%, -50%) rotate(45deg);
}
.data-question-delete-button:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.data-question-answer-title-checkbox .list-item-checkbox{
    width: 20px;
    height: 20px;
}
.data-question-answer-title-checkbox{
    margin-left: 5px;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    display: flex;
    align-items: center;
}
.data-question-answer-title-checkbox span{
    margin-right: 10px;
}

