p,
h3,
h2,
h1,
path,
/*ul,*/
/*li,*/

.policy_main{
    margin: 20px;
    justify-content: center;
    align-items: center;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    padding-left: 10%;
    padding-right: 10%;
}

.policy_back{
    position: absolute;
    min-width:100%;
    min-height:100%;
    background: url("https://roja-rose-images.s3.amazonaws.com/logo.png")  no-repeat center  fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity :0.20
}

.ul-polity-block{
    padding-left: 15%;
}
