.rdg-cell{
    border-bottom :1px solid;
}

.report-buttons-type{
    margin-top:15px;
    margin-left: 15px;
}
.report-buttons-type.MuiButtonGroup-root{
    margin-left: auto;
}

.filter-reports-button.active{
    color:white;
    background-color: #007aff;
}
.block-support-setting{
    height: 150px;
    width: 100%;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
}
.support-block-setting-title{
    top:0;
    left:0;
    margin-left: 25px;
    margin-top: 10px;
}
.support-block-setting-input-block{
    width: 600px ;
    height: auto;
}
.support-setting-input {
    display: block;
    width: 90%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position:relative;
}

.support-setting-button {
    margin-bottom: 15px;
    background-color: #009be5;
    color: white;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    width: 20%;
    height: calc(2.25rem + 2px);
    border-radius: 0.35rem;
    text-align: center;
    border: 1px solid #bdbdbd;

}
.support-setting-button:hover{
    background-color: white;
    color:#009be5;
    border: 1px solid #009be5;
}
.download-file-button{
    margin-bottom: 15px;
    background-color: #009be5;
    color: white;
    font-size: 1.5rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    width: 12%;
    height: calc(2rem + 2px);
    border-radius: 0.35rem;
    text-align: center;
    border: 1px solid #bdbdbd;
}
.gallery-face-verify{
    margin-bottom: 15px;
}
.download-file-button:hover{
    background-color: white;
    color:#009be5;
    border: 1px solid #009be5;
}
.download-div{
    display: flex;
    width: 100%;
    margin: 15px;
    justify-items: center;
    justify-content: center;
}
.download-file-input{
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
}


.user-detail-mail{
    display: flex;
}
.user-detail-block-profile-image{
    width: 15%;
    height: 20%;
    margin: 15px;
    float: left;
}
.user-detail-block-profile-image img{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.35rem;
}
.user-detail-profile-main-info{
    margin: 15px;
    width: 23%;
    height: calc(17rem + 2px);
    float:left;
}
.user-detail-profile-main-info-accordion{
    margin: 15px;
    width: 40%;
    height: calc(17rem + 2px);
    float:left;
}
.user-detail-profile-main-info-accordion-small{
    margin: 15px;
    width: 40%;
    min-height: 100px;
    float:left;
}
.user-detail-profile-info-name{
    width: 100%;
    height: calc(5rem + 2px);
    font-size: 1.1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.user-detail-profile-info-name label{
    width: 30%;
    height: 30%;
    float: left;
    margin-top: 15px;
}
.user-detail-profile-info-name input{
    width: 60%;
    height: 45%;
    border: 2px solid #e1e5ee;
    border-radius: 0.5rem;
    margin: 10px;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    background: none;
    float: right;
    text-align: center;
}
.user-detail-profile-info-name input.invalid{
    border: 2px solid red;
}
.user-detail-profile-info-name select{
    width: 60%;
    height: 45%;
    border: 2px solid #e1e5ee;
    border-radius: 0.5rem;
    margin: 10px;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    background: none;
    float: right;
    text-align: center;
}

.user-detail-profile-info-name-accordion{
    width: 100%;
    height: calc(5rem + 2px);
    font-size: 1.1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.user-detail-profile-info-name-accordion label{
    width: 30%;
    height: 30%;
    float: left;
    margin-top: 15px;
}
.user-detail-profile-info-name-accordion input{
    width: 60%;
    height: 45%;
    border: 2px solid #e1e5ee;
    border-radius: 0.5rem;
    margin: 10px;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    background: none;
    float: right;
    text-align: center;
}
.user-detail-block-bio{
    min-height: 100px;
    margin: 15px;
    left:0;
    /*border: 3px solid #eaeff1;*/
    background-color: #f1f3f4;
    border-radius: 0.5rem;
}
.user-detail-block-bio textarea{
    min-width: 98%;
    max-width: 98%;
    min-height: 100px;
    margin: 15px;
    background-color: white;
    object-fit: cover;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.block-name{
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-color: #f1f3f4;
    font-size: 1.5rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    text-align: center;
    border-radius: 0.5rem;
}
.user-detail-block-gallery{
    display: flex;
    margin :15px;
    background-color: #f1f3f4;
    border-radius: 0.5rem;
}
.user-detail-block-gallery img{
    margin: 15px;
    width: 10%;
    height: 10%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.35rem;
    float: left;
}
.user-detail-block-activities {
    margin: 15px;
    min-height: 70px;
    background-color: #f1f3f4;
    border-radius: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.user-detail-block-activities button {
    min-width: 100px;
    height: 50px;
    margin: 10px;
    background-color: white;
    border-radius: 100px / 50px;
    font-size: 1.2rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.user-detail-block-lifeStyle{
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    background-color: #f1f3f4;
    margin: 15px;
    border-radius: 0.5rem;
    min-height: 100px;
}
.user-detail-block-lifeStyle-types{
    margin: 15px;
    border-radius: 0.5rem;
    background-color: white;
    color: #444;
    cursor: pointer;
    padding: 18px;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
}
.user-detail-block-lifeStyle-types:hover{
    background-color: #ccc;
}
.panel {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
}
.user-detail-block-other{
    margin: 15px;
}
.user-detail-profile-info-name-accordion .padding-active{
    display: block;
    padding-right: 10%;
}
.user-detail-profile-info-name-accordion .label-count {
    position: absolute;
    right: .4em;
    margin-right: 10px;
    text-align: right;
    text-transform: uppercase;
}
.user-detail-text-nothing-results{
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.media-page-gallery-body{
    margin: 15px;
}

.media-page-gallery{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(130px,1fr));
    grid-gap: 15px;
    gap: 15px;
}
.media-page-gallery-image-wrapper{
    width: calc(9rem + 2px);
    height: calc(9rem + 2px);
    position: relative;
}
.media-page-gallery-image-wrapper:hover{
border: 2px solid red;
}
.delete-img {
    opacity: 0;
    position: absolute;
    right: 0;
    width: 2em;
    height: 2em;
    background-color: red;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}
.media-page-gallery-image-wrapper:hover .delete-img{
    opacity: 1;
}

.media-page-gallery .media-page-gallery-image-wrapper img{
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    cursor: grab;
}
.media-page-pagination{
    float: left;
}
.create-new-user{
    margin: 15px;
    background-color: #009be5;
    border-radius: 0.5rem;
    width: 110px;
    height: 40px;
    display: flex;
    justify-content:center;
    align-items:center;
}
.create-new-user:hover{
    background-color: #006db3;
    box-shadow: 1px 3px 3px #bdbdbd;
}
.create-new-user a{
    font-size: 1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: white;

}
.button-create-user{
    position: absolute;
    display: flex;
    width: 100px;
    height: 100px;
    right: 0;
    bottom: 0;
    margin:20px;
    justify-content:center;
    align-items:center;
}
.button-create-user button{
    background-color: #009be5;
    border-radius: 0.5rem;
    width: 90px;
    height: 40px;
    font-size: 1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: white;
}
.button-create-user button:hover{
    background-color: #006db3;
    box-shadow: 1px 3px 3px #bdbdbd;
}
.error-message{
    color: red;
    display: flex;
    justify-content:center;
    align-items:center;
    opacity: 0;
}
.error-message.invalid{
    opacity: 1;
}

.photo-verification-table-img{
    width: 100px;
    height: 100px;
    margin-right: 5px;
    margin-left: 5px;
}
.photo-verification-span-yes{
    width: 50px;
    height: 20px;
    border-radius: 0.5rem;
    color:white;
    font-size: 1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    background-color: limegreen;
    text-align: center;
}
.photo-verification-span-yes.no{
    width: 50px;
    height: 20px;
    border-radius: 0.5rem;
    color:white;
    font-size: 1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    background-color: red;
    text-align: center;

}


.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.red-button {
    background-color: red;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.red-button:hover{
    border: 2px solid #009be5;
}

.green-button {
    background-color: green;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.green-button:hover{
    border: 2px solid #009be5;
}

.decline_modal_div{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.decline_model_content{
    background-color: #fff;
    width: 500px;
    height: auto;
    padding: 20px;
}

.image-row {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
}
.checkbox-img{
    left: 0;
}
.image-row img {
    width: 150px;
    height: 150px;
    border-radius: 1rem;

}
.img-checkbox{
    width: 20px;
    height: 20px;
    border-radius: 1rem;
    right: 10%;
    bottom: 10%;
    position: absolute;
}
.img-checkbox{
    display: none;
}

.img-checkbox-label {
    background-color: white;
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 28px;
    border: 1px solid black;
    cursor: pointer;
    right: 10%;
    bottom: 10%;
}

.img-checkbox-label::before {
    content: "\00D7";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: white;
    background-color: red;
    padding: 0 2px;
    opacity: 0;
}

.img-checkbox:checked + .img-checkbox-label::before {
    opacity: 1;
}
.list-item{
    margin: 10px;
}
.checkbox-list span{
    font-size: 1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    left: 10%;
}
.checkbox-list .list-item-checkbox{
    width: 20px;
    height: 20px;
    right: 10%;
    position: absolute;
}
.close_modal_btn{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 2px;
}
.close_modal_btn:hover{
    background-color: #bdbdbd;
}

.decline-user-div-save{
    left: 38%;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #009be5;
    border-radius: 0.5rem;
    width: 110px;
    height: 40px;
    display: flex;
    justify-content:center;
    align-items:center;
}
.decline-user-div-save:hover{
    background-color: #006db3;
    box-shadow: 1px 3px 3px #bdbdbd;
}
.decline-user-div-save button{
    width: 100%;
    height: 100%;
    font-size: 1rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: white;
}
.successfully-modal-title{
    font-size: 3rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.div-successfully-modal-icon{
    display: flex;
    justify-content:center;
    align-items:center;
}
.setting-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.setting-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 20px;
}
.setting-image:hover{
    border: 2px solid red;
}
.delete-img-face-verify {
    opacity: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 2em;
    height: 2em;
    background-color: red;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.setting-image-div-contain:hover .delete-img-face-verify{
    opacity: 1;
}



