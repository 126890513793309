
.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active{
    opacity: 1;
    pointer-events: all;
}
.modal__content{
    padding: 20px;
    border-radius:12px;
    background-color: white;
    height: auto;
    width: auto;
    transform: scale(0.2);
    transition: 1s all;
}
.modal__content.active{
    transform: scale(1);
}

.report-users{
    display: flex;
    justify-content: space-between;
}
.created-user-content{
    width: 395px;
}
.black-line{
    width: 3px;
    background-color: #009be5;
}
.created-user-content p{
    text-align: center;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 20px;
}
.whom-created-user-content p{
    text-align: center;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 20px;
}
.user-photo {
    width: 100px;
    height: 90px;
}
.user-photo img{
    width: 70px;
    height: 70px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}
.user-info-block{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.block-title{
    background-color: #009be5;
    color: white;
}
.user-detail-block{
    margin-right: 8px;
    text-align: left;
}
.report-info-block{
    margin-top: 15px;
    text-align: center;
    margin-bottom: 15px;

}

.report-info-type-title p{
    text-align: center;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 20px;
}

.report-text-message {
    border: 1px solid #009be5;
    /*display: block;*/
    height: 100%;
    padding: 10px 30px;
    width: 100%;
    text-align: justify;
    display: flex;

}
