*,
*:before,
*:after,
ul,
li,
a,
button,
input,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
image,
svg,
path,
g,
canvas {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    text-decoration: none;
    font-family: "Roboto";
    user-select: auto;
    list-style: none;
    position: relative;
}

.html,
body {
    background-color: #fafafa;
    margin: 0;
    padding: 0;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.12);
    background-color: #f2f8ff;
    text-transform: capitalize;
    cursor: pointer;
}
.button:hover, .button:focus {
    transition: 0.15s ease-in-out;
}
.invalid-login-message{
    font-size: 1.4em;
    font-weight: 300;
    color: #ED2F24;
}
.image-container{
    width: 500px;
    height: 500px;
    background: url("https://roja-rose-images.s3.amazonaws.com/logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
}
.login-container {
    width: 600px;
    height: auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;

}
.login-container .title {
    text-transform: capitalize;
    font-size: 1.6em;
    font-weight: 300;
    padding: 60px 0;
    padding-bottom: 10px;
}
.login-container .login-button {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 60%;
    padding: 20px 15px;
    background-color: #007aff;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    margin: 40px 0;
}
.login-container .login-button:hover, .login-container .login-button:focus {
    box-shadow: 0px 3px 20px 3px rgba(0, 122, 255, 0.3);
    background-color: #007aff;
}

.fluid-input {
    width: 80%;
    height: 50px;
    max-width: 450px;
    position: relative;
}
.fluid-input-bg {
    width: calc(100% - 10%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: absolute;
    left: 5%;
    top: 0;
    color: #007aff;
    font-weight: 400;
    font-size: 0.9em;
    transition: 0.3s ease-in-out;
    text-transform: capitalize;
}
.fluid-input-holder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    will-change: transform, box-shadow;
    transition: 0.2s ease-in-out;
    background-color: #f1f3f4;
}
.fluid-input-input {
    width: inherit;
    height: inherit;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #f1f3f4;
    font-size: 1.05em;
    padding: 0 5%;
}
.fluid-input-input:focus {
    outline: None;
}
.fluid-input-label {
    width: calc(100% - 10%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    position: absolute;
    font-size: 1em;
    left: 5%;
    top: 0;
    color: #007aff;
    font-weight: 400;
    text-transform: capitalize;
    pointer-events: none;
    transition: transform 0.2s ease-in-out, font-size 0.2s 0.15s ease-in-out;
    will-change: transform, font-size;
}
.fluid-input--focus .fluid-input-holder {
    box-shadow: 0px 5px 20px 0px rgba(0, 12, 47, 0.3);
}
.fluid-input--focus .fluid-input-bg {
    transform: translateY(-40px);
    transition: 0.2s 0.2s ease-in-out;
}
.fluid-input--focus .fluid-input-label {
    transform: translateY(-40px);
}
.fluid-input--open .fluid-input-label {
    transform: translateY(-40px);
}
